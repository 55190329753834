<template>
  <component :is="productComponent" :product="product" :company="company" @completed="completed" />
</template>

<script>
  import GenericProduct from '@/components/HireUs/SimpleProductSpecializations/Generic'

  const PRODUCT_VIEWS = new Proxy({
    compliance:      () => import('@/components/HireUs/SimpleProductSpecializations/Compliance'),
    'law-on-call':   () => import('@/components/HireUs/SimpleProductSpecializations/LawOnCall'),
    'virtual-phone': () => import('@/components/HireUs/SimpleProductSpecializations/PhoneService'),
    'monitoring': () => import('@/components/HireUs/SimpleProductSpecializations/MonitoringService'),
  }, { get: (target, name) => (name in target) ? target[name] : GenericProduct })

  export default {
    name: 'SimpleCheckout',

    props: {
      product: Object,
      company: Object,
    },

    computed: {
      productComponent() {
        return PRODUCT_VIEWS[this.product.category]
      },
    },

    methods: {
      completed() {
        this.$emit('completed')
      },
    },
  }
</script>
