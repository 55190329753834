<template>
  <div v-if="!loaded" class="d-flex justify-content-center mb-3">
    <ct-centered-spinner />
  </div>
  <div v-else>
    <b-card>
      <b-card-text>
        <div v-if="isEligibleForTrial">
          <h3 class="mt-0">
            {{ `${product.name} - Free Trial (${product.trial_duration_days} Days)` }}
            &mdash; {{ product.price | dollars }}
          </h3>
          <p>After {{ product.trial_duration_days }} days, the price will be adjusted to {{ product.price | dollars }} per month.</p>
        </div>
        <h3 v-else class="mt-0">
          {{ product.name }} &mdash; {{ product.price | dollars }}
        </h3>

        <div v-if="product.schema">
          <h3 class="text-center">
            Required information
          </h3>

          <schema-form
            ref="productData"
            v-model="productData"
            :fields="product.schema"
            :show-company-mailing-address-option="true"
          />
        </div>
      </b-card-text>

      <b-button
        variant="primary"
        class="float-right mt-1"
        aria-label="add to cart button"
        @click="showConfirmationModal(productData)"
      >
        Add to Cart
      </b-button>
      <confirmation-modal
        :id="modalId"
        :title="modalValues?.title"
        :ok-button-text="modalValues?.okButtonText"
        :cancel-button-text="modalValues?.cancelButtonText"
        @ok="ok"
        @cancel="cancel"
      >
        <div v-html="modalValues?.innerHtml" />
      </confirmation-modal>
    </b-card>
  </div>
</template>

<script>
  import { dollars } from '@/filters'
  import { mapActions, mapGetters } from 'vuex'
  import { PRODUCTS_WITH_SHIPPING } from '@/common/modules/constants'
  import ConfirmationModal from '@/components/ConfirmationModal.vue'
  import { sslModalMixin } from '@/mixins/sslModalMixin'

  export default {
    name: 'GenericProductSpecialization',

    filters: {
      dollars,
    },

    components: {
      ConfirmationModal,
      SchemaForm: () => import('@/components/SchemaForm'),
      CtCenteredSpinner: () => import('@/components/shared/CtCenteredSpinner'),
    },

    mixins: [sslModalMixin],

    props: {
      product: Object,
      company: Object,
    },

    data() {
      return {
        productData: {},
        isEligibleForTrial: false,
        loaded: false,
      }
    },

    computed: {
      ...mapGetters('checkout', [
        'findCartItem',
        'cartItemShippingAddressIsForeign',
        'overseasShippingFeeProduct',
        'cartItems',
      ]),
      ...mapGetters('companies', ['domesticRegistrationMailingAddressIsForeign']),
    },

    watch: {
      cartItems: {
        handler() {
          this.checkTrialEligibility(this.product)
        },
      },
    },

    async mounted () {
      this.loaded = false
      await this.checkTrialEligibility(this.product)
      this.loaded = true
    },

    methods: {
      ...mapActions('checkout', ['addToCart']),
      ...mapActions('freeTrials', ['determineTrialEligibility']),
      async checkTrialEligibility(product) {
        if (!product.has_free_trial) {
          this.isEligibleForTrial = false
          return
        }
        const result = await this.determineTrialEligibility({
          company_id: this.company.id,
          product_category: product.category,
        })
        this.isEligibleForTrial = !!result
      },
      async showConfirmationModal(productData) {
        if (this.product.category === 'domain-ssl' && (await this.showModal())) {
          this.$bvModal.show(this.modalId)
        } else {
          await this.submitted(productData)
        }
      },
      async submitted(productData) {
        if(this.validate(productData)) {
          const addedToCart = await this.addToCart({ ...this.product, data: productData })
          if (addedToCart) {
            // Overseas Shipping Fee
            const parentItem = this.findCartItem(this.product.id)
            const overseasDelivery = this.cartItemShippingAddressIsForeign(parentItem) ? true : this.domesticRegistrationMailingAddressIsForeign
            if (overseasDelivery && PRODUCTS_WITH_SHIPPING.includes(this.product.name.toLowerCase()) && this.overseasShippingFeeProduct) {
              await this.addToCart({
                ...this.overseasShippingFeeProduct,
                parentItemId: parentItem.id,
              })
            }

            this.$emit('completed')
          }
        }
      },
      validate() {
        if (!this.$refs.productData) {
          return true
        }

        return this.$refs.productData.form.validate()
      },
    },
  }
</script>
