var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.productComponent, {
    tag: "component",
    attrs: { product: _vm.product, company: _vm.company },
    on: { completed: _vm.completed },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }