import { mapActions, mapGetters } from 'vuex'

export const sslModalMixin = {

  data() {
    return {
      modalId: "ssl-product-confirmation-modal",
      modalValues: null,
      default: {
        title: `Add ${this.product.name} to Shopping Cart?`,
        innerHtml: `We noticed <strong>${this.company.name}</strong> doesn't have
          <strong>Website Hosting</strong> or <strong>Business Email</strong>
          requiring <strong>Business SSL</strong>, nor is there any service included in your
          <strong>Shopping Cart</strong> requiring <strong>Business SSL</strong>. We suggest
          you only purchase <strong>Business SSL</strong> if you have&mdash;or are planning
          to purchase&mdash; <strong>Website Hosting</strong> or <strong>Business Email</strong>
          for <strong>${this.company.name}</strong> requiring <strong>Business SSL</strong>.`,
        okButtonText: "Add Business SSL Anyways",
        okButtonFunction: this.submitted,
        cancelButtonText: "OK, Don't Add Business SSL",
      },
      unredeemed: {
        title: `Add ${this.product.name} to Shopping Cart?`,
        innerHtml: `We noticed <strong>${this.company.name}</strong> already has an unredeemed
           <strong>Business SSL</strong> voucher. Please consider redeeming it before purchasing another.`,
        okButtonText: "Add Business SSL Anyways",
        okButtonFunction: this.submitted,
        cancelButtonText: "Redeem SSL Voucher",
        cancelButtonFunction: this.routeToSsl,
      },
    }
  },
  computed: {
    ...mapGetters('vouchers', [
      'vouchersByProductCategoryCompanyId',
      'unRedeemedVoucherByProductCategoryCompanyId',
    ]),
    ...mapGetters('checkout', [
      'cartItemByCategory',
      'cartProductBundleItems',
    ]),
    ...mapGetters('domains', [
      'domainSsl',
    ]),
    ...mapGetters('companies', [
      'hasProductBundleInCartByProductCategory',
    ]),
  },
  methods: {
    ...mapActions('vouchers', ['fetchCompanyVouchers']),
    ...mapActions('domains', ['fetchDomainSsl', 'fetchDomains']),
    async ok(params) {
      if (this.modalValues?.okButtonFunction) {
        await this.modalValues?.okButtonFunction(params)
      }
    },
    async cancel(params) {
      if (this.modalValues?.cancelButtonFunction) {
        await this.modalValues?.cancelButtonFunction(params)
      }
    },
    async showModal() {
      await this.fetchVouchers()

      this.modalValues = this.default

      return !this.servicesMissingSsl()
    },
    async fetchVouchers() {
      await this.fetchCompanyVouchers(this.company.id)
    },
    servicesMissingSsl() {
      const websiteHostingVouchers = this.vouchersByProductCategoryCompanyId('business-website-hosting', this.company.id)
      const emailVouchers = this.vouchersByProductCategoryCompanyId('business-email', this.company.id)
      const sslVouchers = this.vouchersByProductCategoryCompanyId('domain-ssl', this.company.id)

      if (websiteHostingVouchers.length > sslVouchers.length || emailVouchers.length > sslVouchers.length) return true

      if (this.hasUnredeemedSslVoucher()) return false

      return this.cartItemsUseSsl()
    },
    hasUnredeemedSslVoucher() {
      if (this.unRedeemedVoucherByProductCategoryCompanyId('domain-ssl', this.company.id)) {
        this.modalValues = this.unredeemed
        return true
      }
    },
    cartItemsUseSsl() {
      const cartWebsiteHosting = this.cartItemByCategory('business-website-hosting')
      const cartEmail = this.cartItemByCategory('business-email')
      const cartBizIdBundle = this.hasProductBundleInCartByProductCategory('business_identity')

      return cartWebsiteHosting || cartEmail || cartBizIdBundle
    },
    async getWebsiteIdWithoutSsl() {
      const domains = await this.fetchDomains()

      const company_domains = domains.filter((domain) => { return domain.company_id === this.company.id })

      const promises = company_domains.map((domain) =>
        this.fetchDomainSsl({ id: domain.id })
      )

      const domainSsl = await Promise.all(promises)

      const index = domainSsl.findIndex((ssl) => ssl === null)

      return index !== -1 ? company_domains[index].id : null
    },
    async routeToSsl() {
      const id = await this.getWebsiteIdWithoutSsl()
      if (id) await this.$router.push({ name: 'website', params: { id: id, activeTab: 'ssl' } })
    },
  },
}
